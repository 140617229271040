import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ChevronRightIcon from '@mui/icons-material/ChevronRightTwoTone';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import TreeItem from '@mui/lab/TreeItem';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const useMainStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: 'max-content auto',
    },
    wrapper: {
        display: 'inline-flex',
        gap: '0.5rem',
        alignItems: 'center'
    },
    codeBlock: {

    },
    scrollContainer: {
        padding: theme.spacing(.5, 1),
        borderRadius: theme.shape.borderRadius,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
        '& > pre, & .MuiTreeItem-label, & .emptyText': {
            fontFamily: 'Menlo,Consolas,"Droid Sans Mono",monospace',
        },
        '& .MuiTreeItem-content.Mui-disabled' : {
            opacity: '1',
        },
        '& .emptyText': {
            fontStyle: 'italic',
        } ,
        '& .emptyIcon': {
            display: 'inline-block',
            width: "20px"
        }
    }
}));


function JobErrorDetails(props) {
    const classes = useMainStyles();
    const [open, setOpen] = React.useState(false);

    const { job_name, job_id, job_error, onResubmit, onHide, title } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton title={title} color="error" onClick={handleClickOpen}>
                <ErrorTwoToneIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogTitle>
                    <FormattedMessage id="error_detail" values={{ name: job_name, job_id }}/>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.scrollContainer}>
                        <pre>
                            {job_error}
                        </pre>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <FormattedMessage id="close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default JobErrorDetails;
