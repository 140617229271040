import React from 'react';

import { useUser } from 'contexts/user';
import { WSURL } from 'utils/constants';
// import { useId } from 'react';
import { IdProvider, useId, useGetId } from 'react-use-id-hook';

const SocketContext = React.createContext();
SocketContext.displayName = "SocketContext";

function useSocketListener(channel, action, dependencies = []) {
    const context = React.useContext(SocketContext);
    const instanceId = useId;

    if (!context) {
        throw new Error('useEvent must be used within a EventProvider');
    }

    const { websocket, registerChannel, unregisterChannel, sendMessage } = context;

    const callback = React.useCallback((event) => {
        const eventData = JSON.parse(event.data);
        if (eventData.channel === channel) {
            action(event);
        }
    }, [channel, action]);

    React.useEffect(() => {
        if (channel && action && websocket) {
            registerChannel(channel, callback, instanceId)
            return () => {
                const removeCallback = action();
                unregisterChannel(channel, callback, removeCallback, instanceId);
            }
        }
        return undefined;
    }, [callback, websocket, instanceId]);

    return { registerChannel, unregisterChannel, sendMessage };
}

function SocketProvider(props) {
    const { user, api, auth } = useUser();


    const webSocketRef = React.useRef();
    const messageQueue = React.useRef([]);

    const token = auth.getIdToken();

    const [readyState, setReadyState] = React.useState('UNINITIATED');
    const [registeredChannels, setRegisteredChannels] = React.useState([]);

    const webSocketReadyState = webSocketRef.current && webSocketRef.current.readyState;

    const handleClose = React.useCallback((e) => {
        console.debug("CLOSE", e);
        setReadyState("CLOSED");
    }, []);

    const handleError = React.useCallback((e) => {
        console.debug("ERROR", e);
        setReadyState("ERROR");
    }, []);

    const handleOpen = React.useCallback((e) => {
        console.debug("OPEN", e);
        const message = JSON.stringify({ token });
        webSocketRef.current.send(message);
        setReadyState("OPEN");
    }, [token]);

    const handleMessage = React.useCallback((e) => {
        console.debug("MESSAGE", e);
    }, []);

    const sendMessage = React.useCallback((message, fromQueue=false) => {
        if (webSocketRef.current && webSocketRef.current.readyState === webSocketRef.current.OPEN && readyState === 'OPEN') {
            console.debug('Sending message')
            webSocketRef.current.send(message);
        } else {
            console.debug('adding message to queue')
            messageQueue.current.push(message);
        }
    }, [webSocketReadyState, readyState]);

    const sendJSONMessage = React.useCallback((message) => {
        sendMessage(JSON.stringify(message));
    }, [webSocketReadyState, readyState]);

    const registerChannel = React.useCallback((channel, callback, instanceId) => {
        console.debug(`[${channel}]`, `Adding message for ${channel} ${callback}`)
        const message ={ channel, register: true };

        webSocketRef.current.addEventListener('message', callback);

        setRegisteredChannels((currentChannels) => {
            const shouldRegister = !currentChannels.find((registration) => registration.channel === channel);
            console.debug("Registration ", shouldRegister, channel, instanceId)
            if (shouldRegister) {
                console.debug('sending message ', channel, instanceId)
                sendJSONMessage(message);
            } else {
                console.debug('not sending message ', channel, instanceId)
            }

            if (currentChannels.find((registration) => registration.instanceId === instanceId)) {
                console.debug("already exists in channels")
                return currentChannels;
            }
            console.debug("adding to Registration ", [...currentChannels, {instanceId, channel}])
            return [...currentChannels, {instanceId, channel}];
        });


    }, [sendMessage, sendJSONMessage]);

    const unregisterChannel = React.useCallback((channel, callback, cleanUpCallback, instanceId) => {
        console.debug(`[${channel}]`, `removing message for ${channel} ${callback}`)
        const message = { channel, unregister: true };

        webSocketRef.current.removeEventListener('message', callback);

        if (cleanUpCallback) {
            cleanUpCallback();
        }

        setRegisteredChannels((currentChannels) => {
            const shouldUnregister = currentChannels.filter((registration) => registration.channel === channel).length === 1;
            console.debug("UNRegistration ", shouldUnregister, channel, instanceId)
            if (shouldUnregister) {
                console.debug('sending message ', channel, instanceId)
                sendJSONMessage(message);
            } else {
                console.debug('not sending message ', channel, instanceId)
            }
            console.debug("removing from Registration ", currentChannels.filter((registration) => registration.instanceId !== instanceId))
            return currentChannels.filter((registration) => registration.instanceId !== instanceId);
        });

    }, [sendMessage, sendJSONMessage]);

    const initialize = React.useCallback(() => {
        console.debug(`Opening socket for ${WSURL}`);
        setReadyState('UNINITIATED');

        const ws = new WebSocket(WSURL);
        ws.addEventListener('open', handleOpen);
        ws.addEventListener('close', handleClose);
        ws.addEventListener('error', handleError);
        ws.addEventListener('message', handleMessage);
        ws.addEventListener('keepalive', handleMessage);
        webSocketRef.current = ws;
    }, [handleOpen]);

    React.useEffect(() => {

        if (webSocketRef.current) {
            webSocketRef.current.close && webSocketRef.current.close();
        }

        initialize();

        return () => {
            console.debug("unmounting Socket", !!webSocketRef.current.close );
            webSocketRef.current.close && webSocketRef.current.close();
        }
    }, [token]);

    React.useEffect(() => {
        console.debug('useEffect',webSocketReadyState)
        if (webSocketRef.current.readyState === webSocketRef.current.OPEN && readyState === 'OPEN') {
            messageQueue.current.splice(0).forEach((message) => sendMessage(message, true));
        }
    }, [webSocketReadyState, readyState])

    return <SocketContext.Provider value={{ websocket: webSocketRef.current, registerChannel, unregisterChannel, sendMessage }} {...props} />;
}

export { SocketProvider, SocketContext, useSocketListener };
