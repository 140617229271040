import React from 'react';
import { FormattedDateParts } from 'react-intl';

const dateTimeFormatProps = {
    // hour: "numeric",
    // second: "numeric",
    // minute: "numeric",
    // year: "numeric",
    // day: "numeric",
    // month: "numeric",
    timeStyle: "medium",
    dateStyle: "short",
    hourCycle: "h24",
}

function StandardDataDate(props) {
    const { value } = props;

    return (
        <FormattedDateParts value={value} {...dateTimeFormatProps}>
            {
                (parts) => {
                    return parts.map(({value, type}) => type === 'literal' ? {[value]: value, ' h ': ':', ' min ': ':', ' s': ''}[value] : value).join('')
                }
            }
        </FormattedDateParts>
    )
}

export default StandardDataDate;