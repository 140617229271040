import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import uniqueId from 'lodash/uniqueId';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import FormSelect from 'components/FormSelect';
import JobTypeOptions from 'components/staccato/Jobs/JobTypeOptions';
import { useUser } from 'contexts/user';
import { useIntl } from 'react-intl';
import { APIURL, WSURL } from 'utils/constants';


const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    label: {
        cursor: 'pointer',
    },
    grid: {
        display: 'grid',
        gridGap: theme.spacing(2, 4),
        paddingBottom: theme.spacing(2),
        alignItems: 'flex-start',
    },
    container: {
        paddingTop: 0,
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: '100%'
    },
    message: {
        overflow: 'hidden',
        flexShrink: 0
    },
    progressContainer: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: theme.zIndex.snackbar + 1, 
    },
    progressBox: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
    },
    snackbar: {
        zIndex: theme.zIndex.snackbar + 1, 
    },
}));

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function AddJob(props) {
    const { jobTypes = [] } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {client } = useParams();

    const { api, user: { email: userEmail } } = useUser();
    const classes = useStyles();

    const defaultJobData = { output_email: userEmail };
    const [error, setError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [jobType, setJobType] = useState('AUDIO_TRANSCRIPTION');
    const [jobName, setJobName] = useState('');
    const [jobData, setJobData] = useState(defaultJobData);
    const [dataValid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const intl = useIntl();

    // State variables for upload progress
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'uploading', 'creating', 'completed'
    const [controller, setController] = useState(null);

    const canCreate = dataValid && !isEmpty(jobName);
    const openDialog = () => {
        setDialogOpen(true);
    };

    const handleCancel = useCallback(() => {
        setLoading(false);
        setError(false);
        setDialogOpen(false);
        setJobName('');
        setUploadProgress(0);
        setUploadStatus('idle');
    }, []);

    const handleCancelUpload = () => {
        if (controller) {
            controller.abort();
        }
        setIsUploading(false);
        setUploadProgress(0);
        setUploadStatus('idle');
    };

    const createJob = useCallback(async () => {
        if (!canCreate) {
            return;
        }

        setLoading(true);
        setError(false);

        const { audio_files, ...job_data } = jobData;
        const formData = new FormData();
        formData.append('job_data', JSON.stringify({
            job_name: jobName,
            ...job_data,
            transcriber: 'whisper'
        }));

        if (!isEmpty(audio_files)) {
            audio_files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            const abortController = new AbortController();
            setController(abortController);
            setIsUploading(true);
            setUploadStatus('uploading');

            try {
                const response = await api.post(
                    `/staccato/job/${jobType.toLowerCase()}/${client}`,
                    formData,
                    {
                        signal: abortController.signal,
                        onUploadProgress: (event) => {
                            if (event.lengthComputable) {
                                const progress = Math.round((event.loaded / event.total) * 100);
                                setUploadProgress(progress);

                                if (progress === 100) {
                                    setUploadStatus('creating');
                                }
                            }
                        }
                    }
                );

                setUploadStatus('completed');
                setIsUploading(false);
                setUploadProgress(0);
                setController(null);

                if (response.ok) {
                    enqueueSnackbar(
                        <FormattedMessage id="job_created" />,
                        { variant: 'success', classes: { root: classes.snackbar } }
                    );
                    handleCancel();
                } else {
                    enqueueSnackbar(
                        <FormattedMessage id="job_failed" />,
                        { variant: 'error', classes: { root: classes.snackbar } }
                    );
                    setError(true);
                }
            } catch (error) {
                setIsUploading(false);
                setUploadProgress(0);
                setController(null);

                if (error.name === 'AbortError') {
                    enqueueSnackbar(
                        <FormattedMessage id="upload_cancelled" />,
                        { variant: 'warning', classes: { root: classes.snackbar } }
                    );
                    return;
                }
                console.error('Upload error:', error);
                enqueueSnackbar(
                    <FormattedMessage id="job_failed" />,
                    { variant: 'error', classes: { root: classes.snackbar } }
                );
                setError(true);
            }
        }
        setLoading(false);
    }, [api, canCreate, classes.snackbar, enqueueSnackbar, jobData, jobName, jobType, handleCancel]);

    useEffect(() => {
        if (uploadStatus === 'completed') {
            const timer = setTimeout(() => {
                setUploadStatus('idle');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [uploadStatus]);

    const handleJobTypeChange = useCallback(({ target: { value } }) => {
        setJobType((currentJobType) => {
            if (currentJobType !== value) {
                setJobData(defaultJobData);
            }
            return value;
        });
    }, [defaultJobData]);

    const handleJobNameChange = useCallback(({ target: { value } }) => {
        setJobName(value);
    }, []);

    return (
        <>
            {(uploadStatus === 'uploading' || uploadStatus === 'creating') && (
                <Box className={classes.progressContainer}>
                    <Box className={classes.progressBox}>
                        {uploadStatus === 'uploading' && (
                            <>
                                <CircularProgressWithLabel value={uploadProgress} size={40} />
                                <Typography>
                                    {`${intl.formatMessage({ id: 'uploading' })} (${uploadProgress}%)`}
                                </Typography>
                                {uploadProgress < 100 && (
                                    <Button
                                        size="small"
                                        color="warning"
                                        onClick={handleCancelUpload}
                                    >
                                        <FormattedMessage id="cancel" />
                                    </Button>
                                )}
                            </>
                        )}
                        {uploadStatus === 'creating' && (
                            <>
                                <CircularProgress size={40} />
                                <Typography>
                                    <FormattedMessage id="creating_job" />
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            )}

            <Dialog open={dialogOpen}>
                    <>
                        <DialogTitle>
                            <FormattedMessage id="create_staccato_job" />
                        </DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText paragraph>
                                <FormattedMessage id="select_staccato_job_type" />
                            </DialogContentText>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormSelect
                                    sx={{ m: 1 }}
                                    fullWidth
                                    name="jobType"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={jobType}
                                    onChange={handleJobTypeChange}
                                    label={<FormattedMessage id="job_type" />}
                                >
                                    {
                                        jobTypes.map(({ job_type_id, description, is_active }) => (
                                            <MenuItem key={job_type_id} value={job_type_id} disabled={!is_active}>
                                                {description}
                                            </MenuItem>
                                        ))
                                    }
                                </FormSelect>
                                <TextField
                                    sx={{ m: 1 }}
                                    fullWidth
                                    name="jobName"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    value={jobName}
                                    disabled={!isEmpty(jobData.audio_files)}
                                    onChange={handleJobNameChange}
                                    label={<FormattedMessage id="job_name" />}
                                />
                            </Box> */}

                            <JobTypeOptions
                                jobType={jobType}
                                jobData={jobData}
                                setJobData={setJobData}
                                setValid={setValid}
                                setJobName={setJobName}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>
                                <FormattedMessage id="cancel" />
                            </Button>
                            <Button
                                disabled={!canCreate}
                                onClick={createJob}
                            >
                                <FormattedMessage id="create" />
                            </Button>
                        </DialogActions>
                    </>
            </Dialog>

            <Button onClick={openDialog} variant="contained">
                <FormattedMessage id="add" />
            </Button>
        </>
    );
}

export default AddJob;

