import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';

const FormattedDuration = ({ value, children, unitDisplay, ...props }) => {
    const durationHours = Math.floor(value / 3600000);
    const durationMinutes = Math.floor((value - (durationHours * 3600000)) / 60000);
    const durationSeconds = Math.floor((value - (durationHours * 3600000 + durationMinutes * 60000)) / 1000);

    const intl = useIntl();

    const fractionUnits = [
        durationHours ? intl.formatNumber(durationHours, { style: 'unit', unit: 'hour'}) : null,
        durationMinutes ? intl.formatNumber(durationMinutes, { style: 'unit', unit: 'minute'}) : null,
        durationSeconds ? intl.formatNumber(durationSeconds, { style: 'unit', unit: 'second'}) : null,
    ].filter(x => x)

    if (value === 0) {
        return intl.formatList([intl.formatNumber(0, { style: 'unit', unit: 'second'})], {type: 'unit'})
    }

    return intl.formatList(fractionUnits, {type: 'unit'}) || "test"
};

FormattedDuration.propTypes = {
    value: PropTypes.number.isRequired,
};

FormattedDuration.defaultProps = {
};

export default FormattedDuration;
