import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Auth from 'utils/Auth';
import { LocaleContext } from 'contexts/locale';
import { queryStringFromObj } from 'utils/ofQuery';
import fetch_with_progress from 'utils/fetch_with_progress';
import * as URLS from 'utils/constants';

const defaultTimeout = 240 * 1000;
const logoutInMilliseconds = 3600000; // 1 hour

const UserContext = React.createContext();
UserContext.displayName = 'UserContext';

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

function camelToSnakeCase(key) {
    return key.replace(/([A-Za-z])([0-9])/g, (snip, char, digit) => `${char}_${digit}`).replace(/([A-Z])/g, snip => `_${snip.toLowerCase()}`);
}

function injectCurrentUser(query = {}, lang, auth) {
    const uid = auth.getUID();
    const newQuery = {
        ...query,
    };

    if (uid) {
        newQuery.currentUser = uid;
    }
    if (lang) {
        newQuery.lang = lang;
    }
    return newQuery;
}

function injectUserToken(query = {}, auth) {
    const newQuery = {
        ...query,
    };

    if (auth.getIdToken()) {
        newQuery.user_token = auth.getIdToken();
    }
    return newQuery;
}

function headers(data = {}, auth) {
    const newHeaders = {
        Accept: 'application/json',
        Authorization: `Bearer ${auth.getIdToken()}`,
    };

    if (!isEmpty(data) && !(data instanceof FormData)) {
        newHeaders['Content-type'] = 'application/json';
    }

    return newHeaders;
}

function scopedUrl(scope, apiPath, query) {
    const baseUrl = URLS[`${scope}URL`] || URLS.APIURL;

    const path = apiPath[0] === '/' || apiPath === '' ? apiPath : `/${apiPath}`;
    return `${baseUrl}${path}${queryStringFromObj(query, true)}`;
}

function scopedDevUrl(scope, apiPath, query) {
    const baseUrl = 'http://dev-test/melodie/api/v1/'

    const path = apiPath[0] === '/' || apiPath === '' ? apiPath : `/${apiPath}`;
    return `${baseUrl}${path}${queryStringFromObj(query, true)}`;
}

function useUser() {
    const userContext = React.useContext(UserContext);
    const localeContext = React.useContext(LocaleContext);
    if (!userContext || !localeContext) {
        throw new Error('useUser must be used within a UserProvider and LocaleProvider');
    }

    const [user, setUser, feedBack, setFeedBack, auth] = userContext;
    const [locale, language, setLocale] = localeContext;

    function query(method, path, { queryObj = {}, scope = 'API', lang = language, data = {}, responseType = null, timeout = defaultTimeout, artificialDelay = 0,onUploadProgress = null, onProgress = null, ...options } = {}) {
        let fetcher = fetch;
        const params = {
            method,
            headers: headers(data, auth),
            responseType,
            timeout,
            ...options,
        };



        if (onProgress) {
            fetcher = fetch_with_progress;
            params['onProgress'] = onProgress;
        }

        if (onUploadProgress) {
            fetcher = fetch_with_progress;
            params['onUploadProgress'] = onUploadProgress;
        }


        if (['POST', 'PUT'].includes(method)) {
            if (data instanceof FormData) {
                params.body = data;
            } else {
                params.body = JSON.stringify(data).replace(/"([^"]*)"\s*:/g, camelToSnakeCase);
            }
        }

        if (artificialDelay) {
            const t = new Date();
            return fetcher(scopedUrl(scope, path, injectCurrentUser(queryObj, lang, auth)), params).then((response) => {
                const responseClone = response.clone();
                responseClone.json = async () => {
                    const json = await response.json();
                    const adjustedDelay = artificialDelay - (new Date() - t);
                    if (adjustedDelay > 0) {
                        await sleep(adjustedDelay);
                    }
                    return json;
                };
                return responseClone;
            });
        }

        return fetcher(scopedUrl(scope, path, injectCurrentUser(queryObj, lang, auth)), params);
    }

    function clearUser(fb = {}) {
        setUser({});
        if (auth.isAuthenticated()) {
            auth.logout();
            setFeedBack(fb);
        }
    }

    function isLoggedIn() {
        return auth.isAuthenticated() && auth.getAccessToken();
    }

    async function fetchUser(callback) {
        const response = await query('get', '/user');
        if (response.ok) {
            const data = await response.json();
            if (data) {
                if (data.language) {
                    const l = data.language.toLowerCase();
                    const loc = l.indexOf('fr') === 0 ? 'fr-CA' : 'en-CA';
                    localStorage.setItem('locale', loc);
                    window.recaptchaOptions = { lang: l };
                    setLocale({
                        locale: loc,
                        language: l,
                    });
                }
                if (data.theme) {
                    localStorage.setItem('theme', data.theme);
                }
                setUser(data);
                if (callback) {
                    callback();
                }
            } else {
                clearUser({ valid: false, text: 'INVALID_CREDENTIALS_ERROR' });
            }
        } else {
            let feedback = {};
            switch (response.status) {
                case 401:
                    feedback = { valid: false, text: 'INVALID_EMPLOYEE_ERROR' };
                    break;
                case 403:
                    feedback = { valid: false, text: 'INVALID_CREDENTIALS_ERROR' };
                    break;
                case 452:
                    feedback = { valid: false, text: 'CONNECTION_SERVER_ERROR' };
                    break;
                default:
                    feedback = { valid: false, text: 'CONNECTION_SERVER_ERROR' };
                    break;
            }

            clearUser(feedback);
        }
    }


    function queryWithProgress(method, path, { data = {}, onProgress, ...options } = {}) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            
            if (onProgress) {
                xhr.upload.addEventListener('progress', (event) => {
                    if (event.lengthComputable) {
                        const progress = (event.loaded / event.total) * 100;
                        onProgress(Math.round(progress));
                    }
                });
            }

            xhr.addEventListener('load', () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve({
                        ok: true,
                        json: () => JSON.parse(xhr.responseText)
                    });
                } else {
                    reject(new Error('Upload failed'));
                }
            });

            xhr.addEventListener('error', () => {
                reject(new Error('Network error'));
            });

            // Use the same URL construction as your query function
            let url = path;
            if (path && path[0] === '/') {
                url = encodeURI(`${APIURL}${path}`);
            }

            xhr.open(method, url);
            
            // Use the same headers function as your existing query
            const headersList = headers(data, token);
            Object.entries(headersList).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value);
            });

            xhr.send(data);
        });
    }

    function loginUser(callback) {
        fetchUser(() => {
            query('post', '/logs');
            if (callback) {
                callback();
            }
        });
    }

    return {
        user,
        feedBack,
        auth,
        setFeedBack,
        fetchUser,
        loginUser,
        clearUser,
        isLoggedIn,
        isCurrentUser: (idToCheck) => idToCheck === (user.employee || user.contact),
        api: {
            static(path, queryObj, scope = 'API', lang = language) {
                return scopedUrl(scope, path, injectUserToken(injectCurrentUser(queryObj, lang, auth), auth));
            },
            static_dev(path, queryObj, scope = 'API', lang = language) {
                return scopedDevUrl(scope, path, injectUserToken(injectCurrentUser(queryObj, lang, auth), auth));
            },
            static_clean(path, queryObj, scope = 'API', lang = language) {
                return scopedUrl(scope, path, queryObj);
            },
            get(path, queryObj, options) {
                return query('GET', path, { queryObj, ...options });
            },
            delete(path, queryObj, options) {
                return query('DELETE', path, { queryObj, ...options });
            },
            head(path, queryObj, options) {
                return query('HEAD', path, { queryObj, ...options });
            },
            post(path, data, options) {
                return query('POST', path, { data, ...options });
            },
            put(path, data, options) {
                return query('PUT', path, { data, ...options });
            },
            postWithProgress(path, data, options) {
                return queryWithProgress('POST', path, { data, ...options });
            },
        },
    };
}

function UserProvider(props) {
    const [user, setUser] = React.useState({});
    const [feedBack, setFeedBack] = React.useState({});
    const {
        domain, clientID, redirectUri, allowSignUp,
        audience, responseType, scope, device,
        handleSetSession, handleAuthentication,
        handleLogout, handleLoginWithGoogle, handleLogin,
        ...otherProps
    } = props;

    const auth = new Auth({
        domain, clientID, redirectUri, allowSignUp, audience, responseType, scope, device,
    });

    if (handleSetSession) {
        auth.setEventHandler('setSession', handleSetSession);
    }

    if (handleAuthentication) {
        auth.setEventHandler('handleAuthentication', handleAuthentication);
    }

    if (handleLogout) {
        auth.setEventHandler('logout', handleLogout);
    }

    if (handleLoginWithGoogle) {
        auth.setEventHandler('loginWithGoogle', handleLoginWithGoogle);
    }

    if (handleLogin) {
        auth.setEventHandler('login', handleLogin);
    }

    return (
        <UserContext.Provider
            value={[user, setUser, feedBack, setFeedBack, auth]}
            {...otherProps}
        />
    );
}

UserProvider.propTypes = {
    domain: PropTypes.string,
    clientID: PropTypes.string,
    redirectUri: PropTypes.string,
    allowSignUp: PropTypes.bool,
    audience: PropTypes.string,
    responseType: PropTypes.string,
    scope: PropTypes.string,
    device: PropTypes.string,
    handleSetSession: PropTypes.func,
    handleAuthentication: PropTypes.func,
    handleLogout: PropTypes.func,
    handleLoginWithGoogle: PropTypes.func,
    handleLogin: PropTypes.func,
};

UserProvider.defaultProps = {
    domain: 'mels-auth.auth0.com',
    clientID: 'TdGWqx5mEWnOq0v3WuBlaZMs87VdtvAZ',
    redirectUri: `${window.location.origin}/callback`,
    allowSignUp: false,
    audience: 'https://mels-auth.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid email profile app_metadata user_metadata aggregator_uid',
    device: 'Aggregator app',
    handleSetSession: undefined,
    handleAuthentication: undefined,
    handleLogout: undefined,
    handleLoginWithGoogle: undefined,
    handleLogin: undefined,
};

export { UserProvider, useUser, UserContext };
