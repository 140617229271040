import React from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/ClearTwoTone';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';

import FormSelect from 'components/FormSelect';

function Filter(props) {
    const {
        defaultFilter, onFilterChange, onShowHiddenChange, showHidden,
        jobTypes, jobTypeFilter, onJobTypeChange
    } = props;
    const [filter, setFilter] = React.useState(defaultFilter);
    const stateSetter = {setFilter};

    const handleFilterChange = React.useCallback(() => {
        onFilterChange(filter);
    }, [filter]);

    const handleKeyUp = React.useCallback(({ key }) => {
        if (key === 'Enter') {
            onFilterChange(filter);
        }
     }, [filter]);

    function handleInputChange({ target: { name, value } }) {
        const stateHandler = stateSetter[`set${name[0].toUpperCase()}${name.substring(1)}`];

        if (stateHandler) {
            stateHandler(value);
        }
    }

    function handleClearFilter() {
        setFilter('');
        onFilterChange('');
    }



    function handleClearJobType() {
        onJobTypeChange([]);
    }


    function handleShowHiddenChange() {
        console.log('ssssszzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz')
        onShowHiddenChange((currentValue) => console.log("+++++++++++++++++++++",showHidden,currentValue, currentValue.show_hidden ==undefined, currentValue.show_hidden == true, currentValue.show_hidden ==undefined || currentValue.show_hidden == true));
        // onShowHiddenChange((currentValue) => currentValue.show_hidden ==undefined || currentValue.show_hidden == true ? 'false' : "true");
        // onShowHiddenChange((currentValue) => !currentValue);
        let newValue = showHidden ==undefined || showHidden == "1" ? "0" : "1"
        console.log('++++++++new value',newValue)
        onShowHiddenChange(newValue);
    }

    function handleJobTypeChange({ target: { name, value } }) {
        onJobTypeChange(value);
    }


    return (
         <Stack
            direction="row"
            justifyContent="right"
            alignItems="center"
            spacing={2}
        >
            <ButtonGroup size="small" sx={{ boxShadow: 0 }} color="secondary" >
                {
                    [["0", VisibilityTwoToneIcon], ["1", VisibilityOffTwoToneIcon]].map(([value, Icon]) =>
                        <Button
                            key={value}
                            value={value}
                            sx={{ boxShadow: 0 }}
                            onClick={handleShowHiddenChange}
                            variant={showHidden === value ? 'contained' : 'outlined'}
                        >
                            <Icon />
                        </Button>
                    )
                }
            </ButtonGroup>

            {/* <FormSelect
                fullWidth
                value={jobTypeFilter}
                onChange={handleJobTypeChange}
                multiple
                name="job_type"
                variant="outlined"
                margin="dense"
                size="small"
                label={<FormattedMessage id="job_type" />}
            >
                {
                    jobTypes.map(({ job_type_id, is_active }) => !is_active ? null : (
                        <MenuItem value={job_type_id} key={job_type_id}>
                            <FormattedMessage id={job_type_id} />
                        </MenuItem>
                    ))
                }
            </FormSelect> */}

            <TextField
                fullWidth
                name="filter"
                variant="outlined"
                margin="dense"
                type="search"
                size="small"
                label={<FormattedMessage id="search" />}
                value={filter}
                onChange={handleInputChange}
                onKeyUp={handleKeyUp}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {!filter ? null :
                                <IconButton size="small" onClick={handleClearFilter}>
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            }
                            <IconButton edge="end" onClick={handleFilterChange} size="large">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Stack>
    )

}


export default Filter;
