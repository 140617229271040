import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { useDebouncedCallback } from 'use-debounce';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import FormMultiText from 'components/FormMultiText';
import FormSelect from 'components/FormSelect';
import MountPathInput from 'components/MountPathInput';
import { useUser } from 'contexts/user';
import FileUpload from 'components/FileUpload';

import { Stack, Typography, Divider } from '@mui/material';

const whisperModels = ['tiny', 'small', 'medium', 'large', 'large-v2', 'large-v3'];

const whisperOutputFormats = ['all', 'srt', 'vtt', 'txt', 'tsv', 'json']

const whisperLanguages = [
    { value: '', label: 'auto'},
    { value: 'fr', label: 'french'},
    { value: 'en', label: 'english'}
]

// function isEmailValid(email) {
//     return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email);
// }

function AudioTranscription (props) {
    const { jobData, setJobData, setValid, setJobName } = props;

    // const [ emailInputValue, setEmailInputValue ] = React.useState('');
    // const [ emailError, setEmailError ] = React.useState(false);
    // const [ audioPathValidState, setAudioPathValidState ] = React.useState();
    // const [ outputPathValidState, setOutputPathValidState ] = React.useState();
    const [ inputSelectedMount, inputSetSelectedMount ] = React.useState({});
    const [ outputSelectedMount, outputSetSelectedMount ] = React.useState({});
    const [ inputSelectedPath, inputSetSelectedPath ] = React.useState('');
    const [ outputSelectedPath, outputSetSelectedPath ] = React.useState('');
    const [uploaded_files, setUploadedFiles] = React.useState([]);
    const intl = useIntl();
    const { api } = useUser();

    



    const {
        host, audio_file_path, output_dir, output_email='',
        model='', language='', output_format='', transcriber="whisper",
        maxLineWidth, maxLineCount, diarize=false
    } = jobData;
    // const valid = React.useMemo(() => (
    //     !isEmpty(output_email) && !isEmpty(model) && ((!isEmpty(output_format) && !isEmpty(audio_file_path) && !isEmpty(output_dir) && audioPathValidState === 'valid' && outputPathValidState === 'valid') ||  !isEmpty(uploaded_files))
    // ), [output_email, audio_file_path, output_dir, model, language, output_format, audioPathValidState, outputPathValidState]);
    const valid = React.useMemo(() => (!isEmpty(uploaded_files) && !isEmpty(output_format)), [uploaded_files, output_format]);


    console.log('*****jobData',jobData)
    console.log('Current files:', uploaded_files);

    const handleFilesChange = (files) => {
        setUploadedFiles(files);
        setJobData((current) => ({ ...current, 'audio_files': files }));
        resetMountPathInput()
        console.log('Current files:', uploaded_files);
        setJobName(intl.formatMessage({ id: 'take_name_of_files' }))
      };

    const resetMountPathInput= () => {
        inputSetSelectedMount({})
        outputSetSelectedMount({})
        inputSetSelectedPath('')
        outputSetSelectedPath('')

    }


    function handleJobDataChange({ target: { value, name, type, checked } }) {
        setJobData((current) => ({ ...current, [name]: type === 'checkbox' ? checked : value }));
    }

    React.useEffect(() => {
        setValid(valid);
    }, [valid]);

    React.useEffect(() => {
        if (inputSelectedMount && isEmpty(outputSelectedMount) ) {
            outputSetSelectedMount(inputSelectedMount)
        }
    }, [inputSelectedMount]);

    function getDirectoryPath(filePath) {
        const separator = filePath.includes('\\') ? '\\' : '/';
        let trimmedPath = filePath.endsWith(separator) ? filePath.slice(0, -1) : filePath;
        const lastSeparatorIndex = trimmedPath.lastIndexOf(separator);
        if (lastSeparatorIndex === -1) {
            return '';
        }
        return filePath.substring(0, lastSeparatorIndex + 1);
    }

    React.useEffect(() => {
        if (inputSelectedPath && isEmpty(outputSelectedPath) ) {
            const directoryPath = getDirectoryPath(inputSelectedPath);
            outputSetSelectedPath(directoryPath)
        }
    }, [inputSelectedPath]);
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 4 }} >
            

            {/* <TextField
                sx={{ m: 1, width: '26.5ch' }}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                disabled
                // onChange={handleJobDataChange}
                label={<FormattedMessage id="transcriber" />}
                value={transcriber}
                name={'transcriber'}
            /> */}
            <Stack spacing={2}  sx={{ m: 1, width: '55ch' }}>
            <FileUpload
                onFilesChange={handleFilesChange}
                disabled={inputSelectedPath}
                />
                {/* <Box 
                    sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2 
                    }}
                >
                    <Divider sx={{ flex: 1 }} />
                    <Typography 
                    variant="body1" 
                    color="text.secondary"
                    sx={{ px: 2 }}
                    >
                    <FormattedMessage id="or" />
                    </Typography>
                    <Divider sx={{ flex: 1 }} />
                </Box> */}
                {/* <MountPathInput
                sx={{ m: 1, width: '55ch' }}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                onChange={handlePathChange}
                label={<FormattedMessage id="audio_file_path" />}
                // value={audio_file_path}
                selectedMount={inputSelectedMount}
                setSelectedMount={inputSetSelectedMount}
                path={inputSelectedPath}
                setPath={inputSetSelectedPath}
                name="audio_file_path"
                service="AUDIO_TRANSCRIPTION"
                validPathState={audioPathValidState}
                disabled={!isEmpty(uploaded_files)}
            />
            <MountPathInput
                sx={{ m: 1, width: '55ch' }}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                required
                onChange={handlePathChange}
                label={<FormattedMessage id="output_dir" />}
                selectedMount={outputSelectedMount}
                setSelectedMount={outputSetSelectedMount}
                path={outputSelectedPath}
                setPath={outputSetSelectedPath}
                // updateMount
                // value={output_dir}
                name="output_dir"
                service="AUDIO_TRANSCRIPTION"
                helperText={<FormattedMessage id="output_dir_helper" />}
                validPathState={outputPathValidState}
                disabled={!isEmpty(uploaded_files)}
            /> */}
                </Stack>
            
            {/* <FormMultiText
                sx={{ m: 1, width: '55ch' }}
                value={!output_email ? [] : output_email.split(',')}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                name="output_email"
                label={<FormattedMessage id="email" />}
                onChange={handleEmailChange}
                helperText={<FormattedMessage id={emailError ? "email_invalid" : "email_helper"} />}
                inputValue={emailInputValue}
                error={emailError}
                onInputChange={onEmailInputChange}
                onDelete={onEmailDelete}
            />
            <TextField
                sx={{ m: 1, width: '26.5ch' }}
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                onChange={handleJobDataChange}
                label={<FormattedMessage id="host" />}
                value={host}
                name={'host'}
                helperText={<FormattedMessage id="empty_is_random" />}
            />
            <FormSelect
                sx={{ m: 1, width: '26.5ch' }}
                fullWidth
                required
                name="model"
                variant="outlined"
                margin="normal"
                size="small"
                value={model}
                displayEmpty
                // placeholder={<FormattedMessage id="large-v2" />}
                onChange={handleJobDataChange}
                label={<FormattedMessage id="model" />}
            >
                {
                    whisperModels.map((whisperModel) => (
                        <MenuItem key={whisperModel} value={whisperModel}>
                            {whisperModel}
                        </MenuItem>
                    ))
                }
            </FormSelect> */}
            <FormSelect
                sx={{ m: 1, width: '26.5ch' }}
                fullWidth
                required
                name="language"
                variant="outlined"
                margin="normal"
                size="small"
                value={language}
                onChange={handleJobDataChange}
                displayEmpty
                label={<FormattedMessage id="language" />}
                helperText={<FormattedMessage id="language_helper" />}
            >
                {
                    whisperLanguages.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                            <FormattedMessage id={label} />
                        </MenuItem>
                    ))
                }
            </FormSelect>
            <FormSelect
                sx={{ m: 1, width: '26.5ch' }}
                fullWidth
                required
                name="output_format"
                variant="outlined"
                margin="normal"
                size="small"
                // placeholder={<FormattedMessage id="txt" />}
                displayEmpty
                value={output_format}
                onChange={handleJobDataChange}
                label={<FormattedMessage id="output_format" />}
            >
                {
                    whisperOutputFormats.map((whisperOutputFormat) => (
                        <MenuItem key={whisperOutputFormat} value={whisperOutputFormat}>
                            {whisperOutputFormat}
                        </MenuItem>
                    ))
                }
            </FormSelect>
            <FormControl
                sx={{ m: 1, width: '55ch' }}
                fullWidth
                margin="normal"
                size="small"
            >
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={!!diarize}
                            onChange={handleJobDataChange}
                            name="diarize"
                        />
                    )}
                    label={<FormattedMessage id="diarize"  />}
                />
                <FormHelperText sx={{ ml: 5, mt: -1 }}>
                    <FormattedMessage id="diarize_helper" />
                </FormHelperText>
            </FormControl>
        </Box>
    );
}

export default AudioTranscription;
