import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

import { useUser } from 'contexts/user';
import { useSocketListener } from 'contexts/socket';

function DiscussionBadge(props) {
    const { relation, relationId, children } = props;
    const { api } = useUser();

    const [badgeNumber, setBadgeNumber] = React.useState(undefined);
    const [fetchingUnreadNumber, setFetchingUnreadNumber] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    async function fetchUnreadDiscussionCount(abortController = new AbortController()) {
        try {
            setFetchingUnreadNumber(true);
            setFailed(false);

            const response = await api.get('/discussion/unread/', { relation, relationId }, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setBadgeNumber(data);
            } else {
                setBadgeNumber(undefined);
                setFailed(true);
            }

            setFetchingUnreadNumber(false);
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    const handleEvent = React.useCallback((event) => {
        fetchUnreadDiscussionCount();
    }, []);

    useSocketListener('comment_created', handleEvent);

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchUnreadDiscussionCount(abortController);
        return () => {
            abortController.abort();
        };
    }, [relation, relationId]);

    if (!badgeNumber) {
        return null;
    }

    return (
        <Chip
            size="small"
            color="secondary"
            variant="outlined"
            label={badgeNumber}
        />
    );
}

DiscussionBadge.propTypes = {
    relation: PropTypes.string,
    relationId: PropTypes.number,
};
DiscussionBadge.defaultProps = {
    relation: undefined,
    relationId: undefined,
};

export default DiscussionBadge;
