import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';

import { snakeCase } from 'utils/ofQuery'
import * as jobTypeComponents from 'components/staccato/Jobs/Options';
import ComingSoon from 'components/ComingSoon';

const useStyles = makeStyles((theme) => ({
    comingSoon: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
}));

function JobTypeOptions(props) {
    const { jobType, jobData, setJobData, setValid, setJobName } = props;

    const classes = useStyles();

    if (!Object.keys(jobTypeComponents).map((k) => snakeCase(k).toUpperCase()).includes(jobType)) {
        return <ComingSoon className={classes.comingSoon} message="coming_soon" />
    }

    const [k, JobTypeComponent] = Object.entries(jobTypeComponents).find(([k, v]) => snakeCase(k).toUpperCase() === jobType);

    return <JobTypeComponent jobData={jobData} setJobData={setJobData} setValid={setValid} setJobName={setJobName}/>
}

export default JobTypeOptions;

