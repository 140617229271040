import React, { useState, useRef } from 'react';
import { 
  Box,
  Paper,
  Typography,
  styled,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DropZone = styled(Paper)(({ theme, isDragging, disabled }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  backgroundColor: disabled 
    ? theme.palette.action.disabledBackground 
    : isDragging 
      ? theme.palette.action.hover 
      : theme.palette.background.paper,
  border: `2px dashed ${
    disabled 
      ? theme.palette.action.disabled
      : isDragging 
        ? theme.palette.primary.main 
        : theme.palette.divider
  }`,
  '&:hover': {
    backgroundColor: disabled 
      ? theme.palette.action.disabledBackground 
      : theme.palette.action.hover,
    borderColor: disabled 
      ? theme.palette.action.disabled 
      : theme.palette.primary.main
  },
  opacity: disabled ? 0.6 : 1,
}));

const FileUpload = ({ onFilesChange, accept, disabled = false }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 4 * 1024 * 1024 * 1024;


  const handleDragEnter = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFilesSelection(droppedFiles);
  };

  // const handleFilesSelection = (newFiles) => {
  //   if (disabled) return;
  //   const updatedFiles = [...files, ...newFiles];
  //   setFiles(updatedFiles);
    
  //   if (onFilesChange) {
  //     onFilesChange(updatedFiles);
  //   }
  // };

  const handleFilesSelection = (newFiles) => {
    if (disabled) return;
  
    const validFiles = [];
    const invalidFiles = [];
  
    newFiles.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });
  
    if (validFiles.length > 0) {
      const updatedFiles = [...files, ...validFiles];
      setFiles(updatedFiles);
      if (onFilesChange) {
        onFilesChange(updatedFiles);
      }
    }
  
    if (invalidFiles.length > 0) {
      alert(`The following files are too large and were not added: ${invalidFiles.map(file => file.name).join(', ')}`);
    }
  };
  

  const handleFileInput = (e) => {
    if (disabled) return;
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      handleFilesSelection(selectedFiles);
    }
  };

  const removeFile = (fileToRemove) => {
    if (disabled) return;
    const newFiles = files.filter(file => file !== fileToRemove);
    setFiles(newFiles);
    
    if (onFilesChange) {
      onFilesChange(newFiles);
    }
  };

  const handleDropZoneClick = (e) => {
    if (disabled) return;
    // Only open file dialog if clicking the drop zone directly (not its children)
    if (e.target === e.currentTarget) {
      fileInputRef.current?.click();
    }
  };

  const handleBrowseClick = (e) => {
    if (disabled) return;
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DropZone
        elevation={0}
        isDragging={isDragging}
        disabled={disabled}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleDropZoneClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInput}
          multiple
          accept={accept}
          disabled={disabled}
        />
        
        <Box onClick={e => e.stopPropagation()}>
          <CloudUploadIcon 
            sx={{ 
              fontSize: 40, 
              mb: 2,
              color: disabled ? 'action.disabled' : 'primary.main'
            }} 
          />
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{ color: disabled ? 'text.disabled' : 'text.primary' }}
          >
            <FormattedMessage id="dragAndDropFiles" />
          </Typography>
          <Button
            variant="outlined"
            component="span"
            startIcon={<CloudUploadIcon />}
            sx={{ mt: 2 }}
            onClick={handleBrowseClick}
            disabled={disabled}
          >
            <FormattedMessage id="browseFiles" />
          </Button>
          <Typography 
            variant="body2" 
            sx={{ 
              mt: 1,
              color: disabled ? 'text.disabled' : 'text.secondary'
            }}
          >
            <FormattedMessage id="multipleFilesSupport" />
          </Typography>
        </Box>
      </DropZone>

      {files.length > 0 && (
        <Paper sx={{ mt: 2, p: 2 }} variant="outlined">
          <Typography variant="subtitle1" gutterBottom>
            <FormattedMessage 
              id="selectedFiles" 
              values={{ count: files.length }} 
            />
          </Typography>
          <List dense>
            {files.map((file, index) => (
              <ListItem 
                key={index}
                divider={index < files.length - 1}
                sx={{ py: 1 }}
              >
                <InsertDriveFileIcon 
                  sx={{ 
                    mr: 2, 
                    color: disabled ? 'action.disabled' : 'primary.main' 
                  }} 
                />
                <ListItemText 
                  primary={file.name}
                  secondary={formatFileSize(file.size)}
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    sx: { color: disabled ? 'text.disabled' : 'text.primary' }
                  }}
                  secondaryTypographyProps={{ 
                    variant: 'caption',
                    sx: { color: disabled ? 'text.disabled' : 'text.secondary' }
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton 
                    edge="end" 
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFile(file);
                    }}
                    disabled={disabled}
                    color="error"
                    aria-label={<FormattedMessage id="removeFile" />}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default FileUpload;