import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  IconButton, 
  Tooltip,
  LinearProgress 
} from '@mui/material';
import { 
  Download as DownloadIcon, 
  FilePresent as FileIcon, 
  Check as CheckIcon, 
  Error as ErrorIcon 
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useUser } from 'contexts/user';

const FileDownload = ({ files = [], daysBeforeDelete = 30 }) => {
  const [downloadProgress, setDownloadProgress] = useState({});
  const [downloadStatus, setDownloadStatus] = useState({});
  const { api } = useUser();

  const getFileName = (path) => {
    const normalizedPath = path.replace(/\\/g, '/');
    return normalizedPath.split('/').pop();
  };

  const getDaysUntilDelete = (uploadDate) => {
    const upload = new Date(uploadDate);
    const deleteDate = new Date(upload.getTime() + (daysBeforeDelete * 24 * 60 * 60 * 1000));
    const today = new Date();
    const daysLeft = Math.ceil((deleteDate - today) / (1000 * 60 * 60 * 24));
    return daysLeft;
  };

  const downloadFile = async (filePath) => {
    try {
      setDownloadProgress(prev => ({ ...prev, [filePath]: 0 }));
      setDownloadStatus(prev => ({ ...prev, [filePath]: 'downloading' }));

      const response = await api.post('/storage/download', {
        file_path: filePath
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error_message || 'Failed to download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download = getFileName(filePath);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setDownloadStatus(prev => ({ ...prev, [filePath]: 'completed' }));
    } catch (error) {
      console.error('Download error:', error);
      setDownloadStatus(prev => ({ ...prev, [filePath]: 'error' }));
    }
  };

  return (
    <Paper sx={{ p: 2, maxWidth: 600, mx: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        <FormattedMessage id="downloadFiles" />
      </Typography>
      
      {files.length === 0 ? (
        <Box sx={{ 
          py: 4, 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.secondary'
        }}>
          <Typography>
            <FormattedMessage id="noFilesAvailable" defaultMessage="No files available" />
          </Typography>
        </Box>
      ) : (
        <List>
          {files.map((file) => {
            const fileName = getFileName(file.file_path);
            const status = downloadStatus[file.file_path];
            const daysLeft = getDaysUntilDelete(file.upload_date);
            const isDeleted = file.is_deleted;

            return (
              <ListItem
                key={file.file_path}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  mb: 1,
                  opacity: isDeleted ? 0.6 : 1
                }}
              >
                <ListItemIcon>
                  {status === 'completed' ? <CheckIcon color="success" /> :
                   status === 'error' ? <ErrorIcon color="error" /> :
                   status === 'downloading' ? <DownloadIcon color="primary" /> :
                   <FileIcon />}
                </ListItemIcon>
                
                <ListItemText 
                  primary={fileName}
                  secondary={
                    <Box>
                      {status === 'downloading' && (
                        <Box sx={{ width: '100%', mt: 1 }}>
                          <LinearProgress 
                            variant="determinate" 
                            value={downloadProgress[file.file_path] || 0}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {downloadProgress[file.file_path]}%
                          </Typography>
                        </Box>
                      )}
                      <Typography 
                        variant="body2" 
                        color={isDeleted ? 'error.main' : daysLeft <= 10 ? 'warning.main' : 'text.secondary'}
                      >
                        {isDeleted ? (
                          <FormattedMessage id="fileDeleted" defaultMessage="File deleted" />
                        ) : (
                          daysLeft <= 30 ? (
                            <FormattedMessage 
                              id="daysUntilDelete" 
                              values={{ days: daysLeft }}
                            />
                          ) : null
                        )}
                      </Typography>
                    </Box>
                  }
                />

                <Tooltip title={
                  isDeleted ? 
                    <FormattedMessage id="fileDeleted" /> : 
                    <FormattedMessage id="downloadFile" />
                }>
                  <span>
                    <IconButton
                      onClick={() => downloadFile(file.file_path)}
                      disabled={isDeleted || status === 'downloading'}
                      color={
                        status === 'completed' ? 'success' :
                        status === 'error' ? 'error' :
                        'primary'
                      }
                    >
                      <DownloadIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </ListItem>
            );
          })}
        </List>
      )}
    </Paper>
  );
};

export default FileDownload;