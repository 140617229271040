import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PendingTwoToneIcon from '@mui/icons-material/PendingTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import TextsmsTwoToneIcon from '@mui/icons-material/TextsmsTwoTone';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import SmsFailedTwoToneIcon from '@mui/icons-material/SmsFailedTwoTone';
import MarkChatReadTwoToneIcon from '@mui/icons-material/MarkChatReadTwoTone';
import PreparingTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import Dialog from '@mui/material/Dialog';
import Badge from '@mui/material/Badge';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorDetail from './Error';

const useMainStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'inline-flex',
        gap: '0.5rem',
        alignItems: 'center',
        padding: theme.spacing(1),
        minWidth: theme.spacing(1),
    },
    wrapperWithoutPadding: {
        display: 'inline-flex',
        gap: '0.5rem',
        alignItems: 'center',
        padding: theme.spacing(1),
        minWidth: theme.spacing(1),
        '& button': {
            padding: 0,
        }
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    pending: {
        color: theme.palette.info.main,
    },
    preparing: {
        color: theme.palette.secondary.main,
    },
}));



function JobStatus(props) {
    const classes = useMainStyles();
    const [open, setOpen] = React.useState(false);

    const { job_id, job_name, jobStatuses, callbackStatuses, progress, job_data = {}, job_error, status, end_callback_status, completed_endpoint } = props;

    let statusColorClass = null;
    let StatusIcon;
    let callbackColorClass = null;
    let CallbackIcon = null;
    let IconProps = { color: "inherit" }
    let wrapperClass = classes.wrapper;

    const statusTitle = (jobStatuses.find((props) => props.job_status_id === status) || {}).description;
    const callbackTitle = (callbackStatuses.find((props) => props.status_id === end_callback_status) || {}).description;

    let showCallbackStatus = ['FAILED', 'SUCCESS'].includes(status) && !!completed_endpoint && !!end_callback_status;

    if (status === 'PROCESSING') {
        return (
            <span title={statusTitle} className={classes.wrapper}>
                {
                    !progress ? null : (
                        <Typography variant="caption" component="div" color="text.secondary" style={{fontFamily: '"Roboto Mono", monospace'}}>
                            {`${Math.round(progress)}%`}
                        </Typography>
                    )
                }
                <CircularProgress title={`${progress}%`} size="20px" variant={!progress ? "indeterminate" : "determinate"} value={progress} />
            </span>
        );
    }


    if (end_callback_status === 'FAILED') {
        callbackColorClass = 'error';
        CallbackIcon = SmsFailedTwoToneIcon;
    } else if (end_callback_status === 'PENDING') {
        callbackColorClass = 'pending';
        CallbackIcon = TextsmsTwoToneIcon;
    } else if (end_callback_status === 'SENT') {
        callbackColorClass = 'pending';
        CallbackIcon = ChatBubbleTwoToneIcon;
    } else if (end_callback_status === 'SUCCESS') {
        callbackColorClass = 'success';
        CallbackIcon = MarkChatReadTwoToneIcon;
    } else if (end_callback_status !== null) {
        console.log("missing callback status", end_callback_status)
    }

    if (status === 'FAILED') {
        IconProps = {
            job_error: job_error,
            job_id: job_id,
            job_name: job_name,
        };
        wrapperClass = classes.wrapperWithoutPadding;
        statusColorClass = 'error';
        StatusIcon = ErrorDetail;
    } else if (status === 'CANCELLED') {
        statusColorClass = 'warning';
        StatusIcon = CancelTwoToneIcon;
    } else if (status === 'SUCCESS') {
        statusColorClass = 'success';
        StatusIcon = CheckCircleTwoToneIcon;
    } else if (status === 'PENDING') {
        statusColorClass = 'pending';
        StatusIcon = PendingTwoToneIcon;
    } else if (status === 'PREPARING') {
        statusColorClass = 'preparing';
        StatusIcon = PreparingTwoToneIcon;
    } else if (status !== null) {
        console.log("missing status", status)
    }


    return (
        <span className={[classes[statusColorClass], wrapperClass].join(' ')}>
            {
                !showCallbackStatus ? (
                    <StatusIcon title={statusTitle} {...IconProps} />
                ) : (
                    <Badge
                        color="default"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <CallbackIcon title={callbackTitle} className={classes[callbackColorClass]} />
                        }
                    >
                        <StatusIcon title={statusTitle} {...IconProps} />
                    </Badge>
                )
            }
        </span>
    )
};

export default JobStatus;
