import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';

import { useUser } from 'contexts/user';
import useLocationSearch from 'hooks/useLocationSearch';
// import { useLocationSearch } from 'contexts/locationSearch';
import JobList from './List';
import AddJob from './Add';
import FilterJob from './Filter'


const useMainStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: 'max-content auto',
    },
}));


function Jobs(props) {
    const classes = useMainStyles();
    const [filters, setFilters] = React.useState({});
    const [userFilter, setUserFilter] = useLocationSearch('filter', '');
    const [userJobTypes, setUserJobTypes] = useLocationSearch('job_type', []);
    const [showHidden, setShowHidden] = useLocationSearch('show_hidden', "0");
    // const [showHidden, setShowHidden] = useLocationSearch('show_hidden', false);

    const [pages, setPages] = React.useState({});
    const [selected, setSelected] = React.useState([]);
    const { api } = useUser();
    console.log('showHidden',showHidden)
    const { job_status, job_types=[], workers, active_mounts, callback_status } = filters;

    async function fetchFilters(abortController = new AbortController()) {
        try {
            const response = await api.get('/staccato/job/filters', {}, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setFilters(data);
            }
        } catch (error) {
            if (!abortController.aborted) {
                console.error(error);
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchFilters(abortController);

        return () => {
            abortController.abort();
        }
    }, []);


    return (
        <div className={classes.root}>
            <Toolbar
                sx={{ justifyContent: "space-between" }}
            >
                <AddJob
                    jobStatuses={job_status}
                    jobTypes={job_types}
                    activeMounts={active_mounts}
                />
                <FilterJob
                    jobTypes={job_types}

                    defaultFilter={userFilter}
                    showHidden={showHidden}
                    jobTypeFilter={userJobTypes}

                    onFilterChange={setUserFilter}
                    onShowHiddenChange={setShowHidden}
                    onJobTypeChange={setUserJobTypes}
                />
            </Toolbar>
            <JobList
                jobStatuses={job_status}
                callbackStatuses={callback_status}
                jobTypes={job_types}
                filter={userFilter}
                userJobTypes={userJobTypes}
                showHidden={showHidden}

                setSelected={setSelected}
                selected={selected}
                setPages={setPages}
                pages={pages}
            />
        </div>
    )
}

export default Jobs;
