import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { FormattedMessage } from 'react-intl';

import { useUser } from 'contexts/user';
import { useSnackbar } from 'notistack';

import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

function ActionButtons(props) {
    const {
        sx = {}, buttonProps = {},
        pages, setPages, selected, showHidden, refreshPages,
        onSuccess = () => {}
    } = props;
    const {client } = useParams();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [actionInProgress, setActionInProgress] = React.useState(false);
    const { api } = useUser();

    const actionableSelections = React.useMemo(() => {
        var selectedResubmitable = [];
        var selectedResubmitCallbackable = [];
        var selectedCancellable = [];
        var selectedHidable = [];
        var selectedUnhidable = [];

        Object.entries(pages).forEach(([i, page]) => page.forEach((job) => {
            if (selected.includes(job.job_id)) {
                const {
                    job_id, end_callback_status, completed_endpoint, is_hidden, status,
                } = job;
                if (is_hidden) {
                    selectedUnhidable.push(job_id);
                }
                else {
                    if (['SUCCESS', 'CANCELLED', 'FAILED'].includes(status)) {
                        selectedHidable.push(job_id);
                    }
                }

                if (end_callback_status !== 'SENT' && !!completed_endpoint)
                    selectedResubmitCallbackable.push(job_id);

                if (['PROCESSING', 'PENDING'].includes(status))
                    selectedCancellable.push(job_id);
                else
                    selectedResubmitable.push(job_id);
            }
        }));

        return { selectedResubmitable, selectedResubmitCallbackable, selectedCancellable, selectedHidable, selectedUnhidable };
    }, [pages, selected]);

    const {
        selectedResubmitable,
        selectedResubmitCallbackable,
        selectedCancellable,
        selectedHidable,
        selectedUnhidable
    } = actionableSelections;

    async function manageResponse(jobIds, response, passedMsgId, failedMsgId, successfulCondition = None, successCallback) {
        const modifiedJobs = response.ok ? await response.json() : [];
        const succededJobIds = modifiedJobs.filter(successfulCondition).map(({ job_id }) => job_id);
        closeSnackbar();
        if (response.ok) {
            if (!isEmpty(succededJobIds))
                enqueueSnackbar(<span>{succededJobIds.length} <FormattedMessage id={passedMsgId} /></span>, { variant: 'success' });
            const failedJobIds = jobIds.filter((jobId) => !succededJobIds.includes(jobId));
            if (!isEmpty(failedJobIds))
                enqueueSnackbar(<span>{failedJobIds.length} <FormattedMessage id={failedMsgId} /></span>, { variant: 'error' });

            if (response.ok && successCallback) {
                successCallback();
            }
        }
        else {
            enqueueSnackbar(<span>{jobIds.length} <FormattedMessage id={failedMsgId} /></span>, { variant: 'error' });
        }
        const jobIdsFound = modifiedJobs.map(({ job_id }) => job_id);
        const updatedPages = Object.fromEntries(Object.entries(pages).map(
            ([i, pg]) => [
                Number(i), pg.map((item) => (jobIdsFound.includes(item.job_id)
                    ? { ...item, ...modifiedJobs[jobIdsFound.indexOf(item.job_id)] } : item)) // ...item necessary to keep item.full_count
            ]
        ));
        setPages(updatedPages);
    }

    const handleResubmit = React.useCallback(async (event, callbackOnly = false) => {
        const callback = (response) => manageResponse(selectedResubmitable, response, "jobs_resubmitted", "jobs_not_resubmitted",
            ({ status }) => ['PROCESSING', 'PENDING'].includes(status), onSuccess);
        callAction(`/staccato/jobs/${client}/resubmit`, { job_ids: selectedResubmitable, callback_only: false }, callback);
    }, [selectedResubmitable]);

    const handleResubmitCallback = React.useCallback(async (event, callbackOnly = false) => {
        const callback = (response) => manageResponse(selectedResubmitCallbackable, response, "job_callbacks_resubmitted", "job_callbacks_not_resubmitted",
            ({ end_callback_status }) => end_callback_status == 'SUCCESS', onSuccess)
        callAction(`/staccato/jobs/${client}/resubmit`, { job_ids: selectedResubmitCallbackable, callback_only: true }, callback);
    }, [selectedResubmitCallbackable]);

    const handleCancel = React.useCallback(async () => {
        const callback = (response) =>
            manageResponse(selectedCancellable, response, "jobs_cancelled", "jobs_not_cancelled", ({ status }) => status == 'CANCELLED', onSuccess);
        callAction(`/staccato/jobs/${client}/cancel`, { job_ids: selectedCancellable }, callback);
    }, [selectedCancellable]);

    const handleHide = React.useCallback(async () => {
        const callback = (response) => {
            if (!showHidden && response.ok) {
                refreshPages();
            } else {
                manageResponse(selectedHidable, response, "jobs_hidden", "jobs_not_hidden", ({ is_hidden }) => is_hidden, onSuccess);
            }
        }
        callAction(`/staccato/jobs/${client}/hide`, { job_ids: selectedHidable }, callback);
    }, [selectedHidable, showHidden, refreshPages])

    const handleUnhide = React.useCallback(async () => {
        const callback = (response) => manageResponse(selectedUnhidable, response, "jobs_unhidden", "jobs_not_unhidden", ({ is_hidden }) => !is_hidden, onSuccess);
        callAction(`/staccato/jobs/${client}/unhide`, { job_ids: selectedUnhidable }, callback);
    }, [selectedUnhidable])



    async function callAction(url, data, callback = (response) => { console.log(response) }) {
        const { jobIds } = data;
        const abortController = new AbortController();
        setActionInProgress(true);
        try {
            const response = await api.put(url, data, { signal: abortController.signal });
            if (callback) {
                callback(response)
            }
        } catch (error) {
            if (!abortController.aborted) {
                console.error(error);
            }
        }
        setActionInProgress(false);
    }

    return (
        <Stack direction="row" {...props} >
            <Button
                {...buttonProps}
                color="secondary"
                disabled={actionInProgress || isEmpty(selectedResubmitable)}
                onClick={handleResubmit}>
                <FormattedMessage id="resubmit" />
            </Button>
            {/* <Button
                {...buttonProps}
                color="secondary"
                disabled={actionInProgress || isEmpty(selectedResubmitCallbackable)}
                onClick={handleResubmitCallback}>
                <FormattedMessage id="resubmit_callback" />
            </Button> */}
            <Button
                {...buttonProps}
                color="warning"
                disabled={actionInProgress || isEmpty(selectedCancellable)}
                onClick={handleCancel}>
                <FormattedMessage id="cancel" />
            </Button>
            {isEmpty(selectedUnhidable) ? null :
                <Button
                    {...buttonProps}
                    color="error"
                    disabled={actionInProgress}
                    onClick={handleUnhide}>
                    <FormattedMessage id="unhide" />
                </Button>
            }
            {isEmpty(selectedHidable) ? null :
                <Button
                    {...buttonProps}
                    color="error"
                    disabled={actionInProgress}
                    onClick={handleHide}
                >
                    <FormattedMessage id="hide" />
                </Button>
            }
        </Stack>
    );
}


export default ActionButtons;