import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import every from 'lodash/every';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { useSnackbar } from 'notistack';

import { useUser } from 'contexts/user';
import FormSelect from 'components/FormSelect';

// TODO: fix mapping so its universal!!!!!
const mappedPermissions = {
    distribution: { title: 'DISTRIBUTION', contact: 'distribution', api: 'distribution' },
    invoices: { title: 'INVOICES', contact: 'invoices', api: 'facturation' },
    kdm: { title: 'KDM', contact: 'kdm', api: 'kdm' },
    manageUser: { title: 'MANAGE_USER', contact: 'clientManagement', api: 'clientWebGestion' },
    physicalVault: { title: 'PHYSICAL_VAULT', contact: 'physicalVault', api: 'physicalVault' },
    vod: { title: 'VOD', contact: 'vodOrders', api: 'vodCommande' },
    dcp: { title: 'DCP', contact: 'dcp' },
    staccato: { title: 'TRANSCRIPTION', contact: 'staccato', api: 'staccato'  },
};

const propTypes = {
    userPermission: PropTypes.number.isRequired,
    selectedContacts: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentClient: PropTypes.number.isRequired,
    clientName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    clientDetails: PropTypes.shape({
        contacts: PropTypes.arrayOf(PropTypes.shape({})),
        clientPermissions: PropTypes.shape({}),
    }).isRequired,
    actionRunning: PropTypes.bool.isRequired,
    setActionRunning: PropTypes.func.isRequired,
    fetchClientDetails: PropTypes.func.isRequired,
};


const useStyles = makeStyles((theme) => createStyles({
    name: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const defaultFields = Object.keys(mappedPermissions).reduce((memo, key) => ({ ...memo, [key]: undefined }), {});

function EditContact(props) {
    const {
        currentClient, selectedContacts, userName, clientName,
        clientDetails: { contacts = [], clientPermissions = {} }, userPermission,
        actionRunning, setActionRunning, fetchClientDetails,
    } = props;
    const classes = useStyles();
    const { api } = useUser();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = React.useState(false);
    const [fields, setFields] = React.useState(defaultFields);
    const [modifyingContact, setModifyingContact] = React.useState(false);


    function openModal() {
        setIsOpen(true);
    }

    function closeModal(event=null, reason='') {
        if (reason !== 'backdropClick') {
            clearModal();
        }
    }

    function clearModal() {
        setIsOpen(false);
        setFields(defaultFields);
    }

    async function modifyPermissions(event, abortController = new AbortController()) {
        if (currentClient) {
            const snackId = enqueueSnackbar(<FormattedMessage id="MODIFYING_PERMISSION" />, { variant: 'loading', persist: true });

            const selectedValues = {};
            Object.keys(fields).forEach((key) => {
                if (key in mappedPermissions) {
                    const { [key]: permissionValue = -1 } = fields
                    selectedValues[mappedPermissions[key].api] = permissionValue;
                }
            });
            setActionRunning(true);
            setModifyingContact(true);
            const response = await api.put(`/contacts/${currentClient}`, {
                client: currentClient,
                contacts: selectedContacts,
                createdBy: userName,
                clientName,
                ...selectedValues,
            }, { signal: abortController.signal });

            if (response.ok) {
                clearModal();
                fetchClientDetails(abortController);
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="PERMISSION_MODIFYED" />, { variant: 'success' });
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ERROR_MODIFYING_PERMISSION" />, { variant: 'error' });
            }
            setActionRunning(false);
            setModifyingContact(false);
        }
    }

    function handleInputChange({ target: { value, name } }) {
        console.log('value',name,value)
        setFields({ ...fields, [name]: value });
    }

    function commonPermission(permission, selection) {
        const { [permission]: permissionValue } = fields;

        if ([0, 1, 2].includes(permissionValue)) {
            return permissionValue;
        }

        if (selection && selection.length > 0 && every(selection, mappedPermissions[permission].contact)) {
            return selection[0][mappedPermissions[permission].contact];
        }
        return '';
    }

    if (userPermission !== 2) {
        return null;
    }

    const keyValuePermissions = Object.keys(clientPermissions).map((key) => ({ key, value: clientPermissions[key] }));
    const validPermissions = keyValuePermissions.filter((perm) => perm.value > 0 && perm.key in mappedPermissions);
    const permissions = [
        { value: 0, label: 'NO_ACCES' },
        { value: 1, label: 'DISPLAY' },
        { value: 2, label: 'MANAGEMENT' },
    ];

    const selection = React.useMemo(() => (
        contacts.filter(({ contact }) => selectedContacts.includes(contact)) || []
    ), [selectedContacts]);

    return <>
        <Button
            variant="outlined"
            size="small"
            disabled={!selectedContacts || selectedContacts.length === 0 || actionRunning || modifyingContact}
            onClick={openModal}
        >
            {
                modifyingContact ? (
                    <CircularProgress color="inherit" size="1.54rem" />
                ) : (
                    <FormattedMessage id="EDIT" />
                )
            }
        </Button>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle>
                <FormattedMessage id="PERMISSIONS" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="PERMISSIONS_CHANGE_FOR" />
                </DialogContentText>
                <DialogContentText>
                    {
                        selection.map(({ name, contact }) => (
                            <Chip key={contact} className={classes.name} label={name} />
                        ))
                    }
                </DialogContentText>
                <Grid container spacing={2}>
                    {
                        validPermissions.map(({ key }) => (
                            <Grid item xs={6} key={key}>
                                <FormSelect
                                    fullWidth
                                    margin="normal"
                                    name={key}
                                    value={commonPermission(key, selection)}
                                    onChange={handleInputChange}
                                    label={<FormattedMessage id={mappedPermissions[key].title} />}
                                >
                                    {
                                        permissions.map(({ value, label }) => (
                                            <MenuItem key={value} value={value}>
                                                <FormattedMessage id={label} />
                                            </MenuItem>
                                        ))
                                    }
                                </FormSelect>
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={clearModal}
                >
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                    color="primary"
                    onClick={modifyPermissions}
                >
                    <FormattedMessage id="SUBMIT" />
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

EditContact.propTypes = propTypes;

export default EditContact;
